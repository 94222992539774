import React from "react";
import Button from "../Button";

interface Props {
  handleAction?: () => void;
  actionText: string;
  disabled?: boolean;
}

function PricingPageButton(props: Props) {
  const { handleAction, actionText, disabled } = props;

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <div
        style={{
          maxWidth: "fit-content",
          backgroundColor: "#f54400"
        }}
      >
        <Button disabled={disabled} size="large" handleClick={handleAction}>
          {actionText}
        </Button>
      </div>
    </div>
  );
}

export default PricingPageButton;
