import React, { useState } from "react";
import { useQuery } from "react-query";
import PricingPageButton from "./PricingPageButton";
import { ProductData } from "../../models";
import getProducts from "../../api/getProducts";
import useAuth from "../../hooks/useAuth";
import RadioInput from "../forms/RadioInput";
import "../../styles/PricingPagePremiumButtonContainer.css";

type TKey = string;

interface Props {
  identifier: string;
  handleOpenPopup: (plan: string) => void;
}

function PricingPagePremiumButtonContainer(props: Props) {
  const { getAuthenticationState, login } = useAuth();
  const [selectedPlanLength, selectPlanLength] = useState("year");
  const { status, data, error } = useQuery<ProductData, [TKey]>(
    ["products"],
    getProducts,
    {
      staleTime: 1000 * 60
    }
  );

  const isLoggedIn = getAuthenticationState();

  if (status === "loading") {
    return null;
  }

  if (status === "error" || !data) {
    if (status === "error") {
      console.error(error);
    }

    return <PricingPageButton disabled actionText="Something went wrong" />;
  }

  const selectedPlan = data.products.find(
    ({ pricing }) => pricing.interval === selectedPlanLength
  );

  return (
    <>
      <div className="PricingPagePremiumButtonContainer__switch">
        <RadioInput
          values={[
            ["Billed Monthly", "month"],
            ["Billed Yearly (Save 20%)", "year"]
          ]}
          label=""
          name={props.identifier}
          selectedValue={selectedPlanLength}
          selectValue={selectPlanLength}
        />
      </div>
      <h3 className="PricingPagePremiumButtonContainer__price">
        ${selectedPlan?.pricing.price.USD} / {selectedPlan?.pricing.interval}
      </h3>
      <PricingPageButton
        handleAction={() => {
          if (!isLoggedIn) {
            return login(`/pricing?plan=${selectedPlan!.product}`);
          }

          return props.handleOpenPopup(selectedPlan!.product);
        }}
        actionText="Upgrade to Premium"
      />
      {!isLoggedIn && (
        <p style={{ color: "#16181c" }}>
          Please note: you need to be{" "}
          <span
            style={{
              color: "#16181c",
              textDecoration: "underline",
              cursor: "pointer"
            }}
            onClick={() => login(`/pricing?plan=${selectedPlan!.product}`)}
          >
            logged in
          </span>{" "}
          to subscribe
        </p>
      )}
    </>
  );
}

export default PricingPagePremiumButtonContainer;
