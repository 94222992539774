import React from "react";
import HSeparator from "../HSeparator";
import "../../styles/PricingPageTierSummary.css";
import { useWindowSize } from "react-use";

interface Props {
  planType: "free" | "premium";
  subtitle: string;
  footer: React.ReactNode;
  featureList: string[];
}

function PricingPageTierSummary(props: Props) {
  const { planType, subtitle, featureList, footer } = props;

  const { width } = useWindowSize();

  return (
    <section className="PricingPageTierSummary">
      <h3 className="PricingPageTierSummary__title">{planType}</h3>
      <p className="PricingPageTierSummary__subtitle">{subtitle}</p>
      <p className="PricingPageTierSummary__list-label">
        With our {planType} tier you can:
      </p>
      <ul>
        {featureList.map(feature => {
          return <li key={feature}>{feature}</li>;
        })}
      </ul>
      {footer}
      {width < 800 && (
        <div style={{ maxWidth: "700px" }}>
          <HSeparator dotted />
        </div>
      )}
    </section>
  );
}

export default PricingPageTierSummary;
