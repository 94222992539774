import React from "react";
import PricingPageBreakdown from "./PricingPageBreakdown";
import features from "../../data/pricing/pricing";
import "../../styles/PricingPageBreakdownContainer.css";
import HSeparator from "../HSeparator";
import PricingPagePremiumButtonContainer from "./PricingPagePremiumButtonContainer";

interface Props {
  handleOpenPopup: (plan: string) => void;
}

function PricingPageBreakdownContainer(props: Props) {
  return (
    <section id="breakdown" className="PricingPageBreakdownContainer">
      <h2>Detailed Breakdown</h2>
      <PricingPageBreakdown
        title="Measure"
        subtitle="Cali Skills lets you measure your progress"
        features={features.measureFeatures}
      />
      <PricingPageBreakdown
        title="Perform"
        subtitle="Cali Skills takes the work out of planning your workouts"
        features={features.performFeature}
      />
      <PricingPageBreakdown
        title="Learn"
        subtitle="Cali Skills gives you the knowledge to excel in your fitness journey"
        features={features.learnFeatures}
      />
      <PricingPagePremiumButtonContainer
        handleOpenPopup={props.handleOpenPopup}
        identifier="pricing-page-breakdown"
      />
      <div style={{ width: "700px", margin: "0 auto" }}>
        <HSeparator dotted />
      </div>
    </section>
  );
}

export default PricingPageBreakdownContainer;
