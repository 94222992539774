import React from "react";
import ReactGA from "react-ga";
import PricingPageTierSummary from "./PricingPageTierSummary";
import PricingPageButton from "./PricingPageButton";
import PricingPagePremiumButtonContainer from "./PricingPagePremiumButtonContainer";
import "../../styles/PricingPageTierSummaryContainer.css";
import HSeparator from "../HSeparator";
import { useWindowSize } from "react-use";
import { navigate } from "gatsby";

interface Props {
  handleOpenPopup: (plan: string) => void;
}

function PricingPageTierSummaryContainer(props: Props) {
  const { width } = useWindowSize();

  return (
    <>
      <div className="PricingPageTierSummaryContainer">
        <PricingPageTierSummary
          planType="free"
          subtitle="For those wanting to track their bodyweight fitness progress"
          featureList={[
            "Start tracking the skills you've achieved",
            "Track the max reps/hold time across 7 progressions",
            "Access clear roadmaps for fundamental progressions",
            "Dedicated pages for the major calisthenics exercises"
          ]}
          footer={
            <PricingPageButton
              handleAction={() => {
                ReactGA.event({
                  category: "Subscription",
                  action: "Navigated away",
                  label: "Navigated to home"
                });
                return navigate("/home");
              }}
              actionText="Start using Cali Skills"
            />
          }
        />
        <PricingPageTierSummary
          planType="premium"
          subtitle="For those wanting more freedom to shape their bodyweight fitness
    journey"
          featureList={[
            "Access all of the features from our free plan",
            "Access clear roadmaps for advanced progressions",
            "View charts to visualise your calisthenics journey",
            "Build your own workouts",
            "Follow along your workout in-app",
            "Download your workouts as a handy PDF",
            "Supporting an independent developer"
            // "Adapt your workouts based on available equipment",
            // "Add your own exercises",
            // "Create your own progression roadmaps",
            // "Access additional exercise pages"
          ]}
          footer={
            <PricingPagePremiumButtonContainer
              handleOpenPopup={props.handleOpenPopup}
              identifier="pricing-page-summary"
            />
          }
        />
      </div>
      {width >= 800 && (
        <div style={{ maxWidth: "700px", margin: "0 auto" }}>
          <HSeparator dotted />
        </div>
      )}
    </>
  );
}

export default PricingPageTierSummaryContainer;
