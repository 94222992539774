import React from "react";
import "../../styles/PricingPageHeader.css";
import HSeparator from "../HSeparator";

function PricingPageHeader() {
  return (
    <div className="PricingPageHeader">
      <h1>
        Track your bodyweight fitness journey with our Free and Premium tiers.
      </h1>
      <div style={{ maxWidth: "700px", margin: "0 auto" }}>
        <HSeparator dotted />
      </div>
      <h2>Overview</h2>
      <a href="#breakdown">Or check out our detailed breakdown below</a>
    </div>
  );
}

export default PricingPageHeader;
