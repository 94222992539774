import React, { useEffect, useCallback } from "react";
import ReactGA from "react-ga";
import queryString from "query-string";
import { navigate, Link } from "gatsby";
import useScript from "../hooks/useScript";
import { useEffectOnce } from "react-use";
import generateSnackbar from "../helpers/generateSnackbar";
import NavRoutes from "../components/NavRoutes";
import SEO from "../components/SEO";
import PricingPageHeader from "../components/pricingPage/PricingPageHeader";
import PricingPageTierSummaryContainer from "../components/pricingPage/PricingPageTierSummaryContainer";
import PricingPageBreakdownContainer from "../components/pricingPage/PricingPageBreakdownContainer";
import PricingPageFAQs from "../components/pricingPage/PricingPageFAQs";
import "../styles/PricingPage.css";
import { useLocation } from "@reach/router";
import useAuth from "../hooks/useAuth";

interface Order {
  id: string | null;
}

function PlansPage() {
  const [loaded, error] = useScript(
    "https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.3/fastspring-builder.min.js",
    {
      "data-storefront": process.env.GATSBY_FASTSPRING_STOREFRONT_URL,
      "data-popup-closed": "onPopupClose",
      id: "fsc-api",
      type: "text/javascript"
    }
  );

  const { getUserId, getAuthenticationState } = useAuth();
  const location = useLocation();

  const isLoggedIn = getAuthenticationState();

  async function onPopupClose(order: Order | null) {
    if (!order || !order.id) {
      generateSnackbar({
        message: "Just letting you know, we haven't taken any payment yet",
        type: "info",
        hideAfter: 15
      });

      console.error("there was an error processing your payment");
      return;
    }

    generateSnackbar({
      message:
        "Your payment was successful. We'll take you to the premium tour in few seconds",
      type: "success",
      hideAfter: 5
    });

    ReactGA.event({
      category: "Subscription",
      action: `Subscribed to Plan`,
      label: `Successfully Cancelled Plan`
    });

    setTimeout(() => {
      navigate("/tour");
    }, 5000);
  }

  const openPopup = useCallback(
    (planType: string) => {
      const userId = getUserId();

      ReactGA.event({
        category: "Subscription",
        action: "Open Pricing Modal",
        label: `Open pricing modal for ${planType}`
      });

      window.fastspring.builder.tag("userId", userId);
      window.fastspring.builder.add(planType);
      window.fastspring.builder.checkout();
    },
    [getUserId]
  );

  useEffect(() => {
    if (!isLoggedIn || !loaded) return;

    const parsedSearch = queryString.parse(location.search);

    if (!parsedSearch.plan) return;

    openPopup(parsedSearch.plan as string);

    ReactGA.event({
      category: "Subscription",
      action: `Open Pricing Modal`,
      label: "Directed to pricing modal from sign up",
      nonInteraction: true
    });

    navigate("/pricing");
  }, [location, openPopup, isLoggedIn, loaded]);

  useEffectOnce(() => {
    window.onPopupClose = onPopupClose;
  });

  if (!loaded || error) {
    return null;
  }

  return (
    <div className="PricingPage">
      <NavRoutes />
      <SEO
        path="/pricing"
        description="Learn how Cali Skills Premium can help you achieve your bodyweight fitness goals."
        title="Pricing | Cali Skills"
      />
      <div className="PricingPage__content">
        <PricingPageHeader />
        <PricingPageTierSummaryContainer handleOpenPopup={openPopup} />
        <div style={{ maxWidth: "700px", margin: "0 auto" }}>
          <PricingPageBreakdownContainer handleOpenPopup={openPopup} />
          <footer>
            <PricingPageFAQs />
            <div style={{ paddingBottom: "16px" }}>
              <Link style={{ color: "#f54400" }} to="/privacy-policy/">
                Privacy Policy
              </Link>
            </div>
            <div style={{ paddingBottom: "24px" }}>
              <Link style={{ color: "#f54400" }} to="/terms-and-conditions/">
                Terms and Conditions
              </Link>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default PlansPage;
