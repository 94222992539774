import React from "react";
import "../../styles/PricingPageFAQs.css";
import AccordionGroup, { AccordionData } from "../base/AccordionGroup";
import HSeparator from "../HSeparator";

const faqData: AccordionData[] = [
  {
    key: 0,
    title: "What's the refund policy?",
    content: (
      <p>
        We are happy to provide refunds within 7 days of purchasing a monthly or
        annual subscription of Cali Skills.
      </p>
    )
  },
  {
    key: 1,
    title: "What happens to my progress when I downgrade?",
    content: (
      <p>
        Your progress is safe for when you decide to resubscribe to premium, but
        you will no longer be able to access any of the premium features.
      </p>
    )
  },
  {
    key: 2,
    title: "Which devices and browsers are supported?",
    content: (
      <p>
        We work hard to make Cali Skills as openly available as
        possible, so we support up-to-date Safari, Chrome, and Firefox browsers.
        We also support the latest iPhone, Pixel, and Galaxy models, as well as
        other mid/high-end Android devices. If you're unsure as to whether your
        device works with Cali Skills, we encourage you to try our free
        version first.
      </p>
    )
  },
  {
    key: 3,
    title: "Is there an iOS/Android app? (spoilers: no)",
    content: (
      <>
        <p>
          Currently, there is no iOS or Android app for Cali Skills. We want
          this application to be made available to as many people as possible,
          which is why we chose to make it a web app, accessible through the
          browser. This means that anyone with a half-decent phone or computer
          can use our app.
        </p>
        <p>
          Cali Skills is primarily built and mantained by a single person,
          alongside their full time job. This would make maintaining a
          full-featured application an impossibility.
        </p>
      </>
    )
  }
];

function PricingPageFAQs() {
  return (
    <>
      <h2 style={{ color: "#16181c" }}>Frequently Asked Questions</h2>
      <AccordionGroup accordionData={faqData} theme="light" />
      <HSeparator />
    </>
  );
}

export default PricingPageFAQs;
