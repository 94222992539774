import { useState, useEffect } from "react";

const cachedScripts: string[] = [];

function useScript(src: string, attributes: Record<string, string>) {
  const [state, setState] = useState({
    error: false,
    loaded: false
  });

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false
      });
    } else {
      cachedScripts.push(src);

      const script = document.createElement("script");
      script.src = src;
      script.async = true;

      if (attributes) {
        const attributeKeys = Object.keys(attributes);
        attributeKeys.forEach(key => {
          const value = attributes[key];
          if (value) {
            script.setAttribute(key, value);
          }
        });
      }

      const onScriptLoad = () => {
        setState({
          error: false,
          loaded: true
        });
      };

      const onScriptError = (e: ErrorEvent) => {
        console.error(e);

        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          error: true,
          loaded: true
        });
      };

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
      };
    }
  }, [src]);

  return [state.loaded, state.error];
}

export default useScript;
