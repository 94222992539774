import React from "react";
import Tippy from "@tippy.js/react";
import classnames from "classnames";
import { Feature } from "../../data/pricing/pricing";
import { TickIcon, CrossIcon } from "../../icons";
import "../../styles/PricingPageBreakdown.css";
import Icon from "../Icon";

interface Props {
  title: string;
  subtitle: string;
  features: Feature[];
}

function PricingPageBreakdown(props: Props) {
  const { title, subtitle, features } = props;

  return (
    <div className="PricingPageBreakdown__container">
      <h3 className="PricingPageBreakdown__title">{title}</h3>
      <p className="PricingPageBreakdown__subtitle">{subtitle}</p>
      <div
        className="PricingPageBreakdown__feature"
        style={{
          display: "grid",
          color: "black",
          gridTemplateColumns: "4fr 1fr 1fr"
        }}
      >
        <span />
        <div style={{ margin: "0 auto" }}>
          <p className="PricingPageBreakdown__row-header">Free</p>
        </div>
        <div style={{ margin: "0 auto" }}>
          <p className="PricingPageBreakdown__row-header">Premium</p>
        </div>
      </div>
      {features.map(feature => {
        const hasAdditionalInformation = Boolean(feature.additionalInformation);

        return (
          <div
            key={feature.feature}
            className="PricingPageBreakdown__feature"
            style={{
              display: "grid",
              color: "black",
              gridTemplateColumns: "4fr 1fr 1fr"
            }}
          >
            <div>
              <Tippy
                content={feature.additionalInformation ?? ""}
                enabled={hasAdditionalInformation}
              >
                <p
                  tabIndex={hasAdditionalInformation ? 0 : -1}
                  className={classnames(
                    "PricingPageBreakdown__feature-description",
                    {
                      "PricingPageBreakdown__feature-description--additional-info": hasAdditionalInformation
                    }
                  )}
                >
                  {feature.feature}
                </p>
              </Tippy>
            </div>
            <div style={{ margin: "0 auto" }}>
              <Icon
                title={`feature ${!feature.free && "not"} available in free.`}
                src={feature.free ? TickIcon : CrossIcon}
                containerWidth={24}
              />
            </div>
            <div style={{ margin: "0 auto" }}>
              <Icon
                title="feature available in premium."
                src={TickIcon}
                containerWidth={24}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PricingPageBreakdown;
