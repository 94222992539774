import React from "react";
import "../../styles/RadioInput.css";

interface Props {
  values: string[][];
  label: string;
  selectedValue: string;
  name: string;
  selectValue: (value: string) => void;
}

function RadioInput(props: Props) {
  const { values, label, selectedValue, selectValue, name } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <label className="InputLabel">{label}</label>
      <div className="RadioInput__container">
        {values.map(valueTuple => {
          const [title, value] = valueTuple;

          return (
            <div key={value} className="InputContainer RadioInput">
              <input
                type="radio"
                name={name}
                value={value}
                id={`${name}-${value}`}
                data-testid={`${name}-${value}`}
                checked={value === selectedValue}
                onChange={() => selectValue(value)}
              />
              <label
                className="InputLabel RadioInput__label"
                htmlFor={`${name}-${value}`}
              >
                {title}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RadioInput;
