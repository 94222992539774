export interface Feature {
  feature: string;
  free: boolean;
  premium: boolean;
  additionalInformation?: string;
}

const measureFeatures: Feature[] = [
  {
    feature: "Clear roadmaps for fundamental progression",
    additionalInformation:
      "Roadmaps to achieve the Pull Up, Push Up, Squat, Bodyweight Rows, and more",
    free: true,
    premium: true
  },
  {
    feature: "Track your max rep/hold-time for each exercise",
    free: true,
    premium: true
  },
  {
    feature: "Track the skills you've achieved",
    free: true,
    premium: true
  },
  // {
  //   feature: "Create your own progression roadmaps",
  //   free: false,
  //   premium: true
  // },
  {
    feature: "Additional progression roadmaps",
    additionalInformation:
      "Roadmaps for to achieve the Handstand Press, One-Armed Pull Up, and One-Armed Push Up",
    free: false,
    premium: true
  },
  {
    feature: "Charts to visualise your calisthenics journey",
    free: false,
    premium: true
  }
  // {
  //   feature: "Favourite multiple skill trees",
  //   free: false,
  //   premium: true
  // }
];

const performFeature: Feature[] = [
  {
    feature: "Perform personalised workouts in-app",
    additionalInformation:
      "Choose the progressions you'd like to work on, and we'll generate a workout using your progress",
    free: false,
    premium: true
  },
  {
    feature: "Pre-made workout routines",
    additionalInformation:
      "You can generate routines based off Reddit's Recommended Routine, and more",
    free: false,
    premium: true
  },
  {
    feature: "Automically update progress during your workout",
    free: false,
    premium: true
  },
  {
    feature: "Access to the Workout Builder",
    free: false,
    premium: true
  },
  {
    feature: "Download your workouts as a handy PDF",
    free: false,
    premium: true
  }
  // {
  //   feature: "Adapt workouts based on available equipment",
  //   free: false,
  //   premium: true
  // },
  // {
  //   feature: "Save your Workout Builder settings",
  //   free: false,
  //   premium: true
  // },
  // {
  //   feature: "Additional workout routines",
  //   free: false,
  //   premium: true
  // }
];

const learnFeatures: Feature[] = [
  {
    feature: "Pages dedicated for the major calisthenics exercises",
    free: true,
    premium: true
  },
  {
    feature: "Straight-forward guide to calisthenics equipment",
    free: true,
    premium: true
  }
  // {
  //   feature: "Additional exercise pages",
  //   free: false,
  //   premium: true
  // },
  // {
  //   feature: "Form cues for each exercise",
  //   free: false,
  //   premium: true
  // },
  // {
  //   feature: "Form cues spoken during workout",
  //   free: false,
  //   premium: true
  // }
];

export default {
  measureFeatures,
  performFeature,
  learnFeatures
};
