import axios from "axios";

async function getProducts() {
  const { data } = await axios.get(
    `${process.env.GATSBY_SERVER_URL}/products`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  return data;
}

export default getProducts;
